<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-2xl sm:font-extrabold font-montserrat text-center mt-10">{{ $t('companyArea.achievements.companyProfile.title') }}</p>
    </template>

    <div class="panel">
      <div class="text-lg font-semibold">{{ $t('companyArea.achievements.companyProfile.title') }}</div>
      <div class="mt-2 mb-4">{{ $t('companyArea.achievements.companyProfile.text') }}</div>
      <div v-if="companyProfile.hasOwnProperty('id')">
        <media-library-attachment name="logo"
                                  :validation-rules="{ accept: ['image/png', 'image/jpeg'], maxSizeInKB: 5000 }"
                                  :upload-domain="backendUrl"
                                  :route-prefix="'company-backend/companies/' + company.id + '/company-profile/media/logo'"
                                  :initial-value="logo"
                                  @is-ready-to-submit-change="isReadyToSubmit = $event"
                                  @change="onChangeLogo"
        />
<!--        <button v-if="collection === 'logo'"-->
<!--                :disabled="!isReadyToSubmit"-->
<!--                class="btn btn-primary btn-sm mt-4"-->
<!--                @click="submitForm"-->
<!--        >-->
<!--          {{ $t('general.submit') }}-->
<!--        </button>-->

        <!--        <div class="text-lg font-semibold mt-10">Stellen Sie sich vor</div>-->
        <!--        <div class="mt-2 mb-4">-->
        <!--          Optional können Sie eine kurze Vorstellung Ihrer Firma schreiben.-->
        <!--        </div>-->

        <!--        <textarea v-model="formdata.text" class="w-full">-->

        <!--        </textarea>-->

        <!--        <div class="text-lg font-semibold mt-10">Bilder</div>-->
        <!--        <div class="mt-2 mb-4">-->
        <!--          Um bei unseren Benutzern einen besseren Eindruck Ihrer Firma geben zu können, haben Sie die Möglichkeit, einige Bilder hochzuladen. Dies können z.B. Bilder von Ihrem-->
        <!--          Fahrzeugpark sein, oder von Ihnen bei der Arbeit.-->
        <!--        </div>-->

        <!--        <media-library-collection name="images"-->
        <!--                                  :validation-rules="{ accept: ['image/png', 'image/jpeg', 'image/heic'], maxSizeInKB: 5000 }"-->
        <!--                                  upload-domain="http://seo.top-offerten.test"-->
        <!--                                  :route-prefix="'api/blog-posts/companies/' + company.id + '/leads/4567/media/images'"-->
        <!--                                  :initial-value="images"-->
        <!--                                  @is-ready-to-submit-change="isReadyToSubmit = $event"-->
        <!--                                  @change="onChangeImages"-->
        <!--        >-->
        <!--        </media-library-collection>-->

        <!--        <br><br><br>-->

        <!--          <media-library-collection name="videos"-->
        <!--                                    :validation-rules="{ accept: ['image/png', 'image/jpeg'], maxSizeInKB: 500000 }"-->
        <!--                                    upload-domain="http://top-offerten.test"-->
        <!--                                    :route-prefix="'api/blog-posts/companies/' + company.id + '/leads/4567/media/images'"-->
        <!--                                    @is-ready-to-submit-change="isReadyToSubmit = $event"-->
        <!--                                    @change="onChangeImages"-->
        <!--          >-->
        <!--          <template v-slot:properties="{ object }">-->
        <!--            <div class="media-library-property" />-->
        <!--          </template>-->
        <!--        </media-library-collection>-->
        <button v-if="collection === 'images'"
                :disabled="!isReadyToSubmit"
                class="btn btn-primary btn-sm mt-4"
                @click="submitForm"
        >
          {{ $t('general.submit') }}
        </button>

        <div v-if="Object.keys(logo).length > 0" class="mt-5 border shadow p-4">
          <img :src="logo[Object.keys(logo)]?.original_url" width="200">
        </div>
        <div v-if="Object.keys(logo)?.length > 0 && !companyProfile.link_partner_at" class="mt-5 text-red-600 font-semibold cursor-pointer hover:underline"
             @click="$router.push({ name: 'LinkPartnership'})"
        >
          {{ $t('companyArea.achievements.companyProfile.linkPartner') }}
        </div>
      </div>

    </div>

    <div class="mt-10 font-semibold text-base">{{ $t('companyArea.achievements.companyProfile.customerView') }}</div>
    <company-card v-if="companyLead.hasOwnProperty('company')" :company-lead="companyLead"></company-card>

    <div v-if="Object.keys(logo).length > 0 && !companyProfile.link_partner_at">
      <div class="mt-10 font-semibold text-base">{{ $t('companyArea.achievements.linkPartnership.withLinkPartnership') }}</div>
      <company-card v-if="companyLead.hasOwnProperty('company')" :company-lead="companyLeadWithLogo"></company-card>

    </div>

  </layout>
</template>

<script>
  import Layout from '../../Layout.vue';
  import { MediaLibraryAttachment } from '@spatie/media-library-pro-vue3-attachment';
  import { MediaLibraryCollection } from '@spatie/media-library-pro-vue3-collection';
  import companyApi from '../../connections/company';
  import store from '../../store/mainStore';
  import CompanyCard from '../../customerArea/QuoteOverview/CompanyCard.vue';

  export default {
    components: {
      CompanyCard,
      Layout,
      MediaLibraryAttachment,
      MediaLibraryCollection,
    },
    data() {
      return {
        backendUrl: import.meta.env.VITE_VUE_APP_BACKEND_API_URL,
        logo: [],
        images: [],
        isReadyToSubmit: false,
        media: [],
        collection: '',
        formdata: {
          text: '',
        },
      };
    },
    computed: {
      company() {
        return store.state.company.company;
      },
      companyProfile() {
        return store.state.company.companyProfile;
      },
      companyLead() {
        if (this.companyProfile.hasOwnProperty('id')) {
          let companyLead = {};
          companyLead.company = JSON.parse(JSON.stringify(this.company));
          companyLead.quote_logs = [];
          companyLead.company.company_profile = JSON.parse(JSON.stringify(this.companyProfile));
          if (this.companyProfile.media.length > 0) {
            companyLead.company.company_profile.logo = this.companyProfile.media[0].original_url;
          }

          companyLead.website = store.state.company.website;
          companyLead.website.locations = store.state.company.locations;
          return companyLead;
        } else {
          return {};
        }

      },
      companyLeadWithLogo() {
        if (this.companyProfile.hasOwnProperty('id')) {
          let companyLead = {};
          companyLead.company = JSON.parse(JSON.stringify(this.company));
          companyLead.quote_logs = [];
          companyLead.company.company_profile = JSON.parse(JSON.stringify(this.companyProfile));
          companyLead.company.company_profile.link_partner_at = '2000-01-01';
          if (this.companyProfile.media.length > 0) {
            companyLead.company.company_profile.logo = this.companyProfile.media[0].original_url;
          }

          companyLead.website = store.state.company.website;
          companyLead.website.locations = store.state.company.locations;
          return companyLead;
        } else {
          return {};
        }
      },
    },
    created() {
      companyApi.get('/companies/' + this.company.id + '/company-profile/media', { notification: false }).then(response => [store.commit('company/setCompanyProfile', response.data.content.companyProfile), this.logo = response.data.content.logo, this.images = response.data.content.images]).catch();
    },
    methods: {
      onChangeLogo(media) {
        this.collection = 'logo';
        this.media = media;
        companyApi.get('/companies/' + this.company.id + '/company-profile/media', { notification: false }).then(response => [store.commit('company/setCompanyProfile', response.data.content.companyProfile), this.logo = response.data.content.logo, this.images = response.data.content.images]).catch();
      },
      onChangeImages(media) {
        this.collection = 'images';
        this.media = media;
      },
      // submitForm() {
      //   console.log(this.media);
      //   companyApi.patch('/companies/' + this.company.id + '/company-profile/' + this.companyProfile.id + '/media/' + this.collection + '/update', { payload: this.media }, { notification: false }).then(response => this.collection = '').catch();
      // },
    },
  };

</script>

<style scoped>
    .media-library-thumb-image {
        padding: 10px;

    }
</style>
