<template>
  <div class="text-base font-semibold mt-6">{{ $t('companyArea.achievements.linkPartnership.labelTitle1') }}</div>

  <div class="mt-4">{{ $t('general.size') }} <input v-model="size" type="number" class="border rounded"> px</div>
  <div class="script-container">
        <pre id="script-code" class="script-code">
&lt;a href="{{ companyProfile.link }}" target="_blank"&gt;&lt;img id="company-status-label" data-company-id="{{ company.id }}" src="" style="width: {{ size }}px" alt="top-offerten"&gt;&lt;/a&gt;
&lt;script src="https://top-offerten.ch/widget/partnerlabel.js"&gt;&lt;/script&gt;
    </pre>
    <button class="copy-button" @click="copyToClipboard('script-code')">{{ $t('general.copy') }}</button>
  </div>

  <img :src="'https://top-offerten.ch/widget/'+label" :style="'width: '+size+'px'">

  <div class="text-base font-semibold mt-6">{{ $t('companyArea.achievements.linkPartnership.labelTitle2') }}</div>
  <div class="mt-4">{{ $t('companyArea.achievements.linkPartnership.labelWithRatingsText') }}</div>

  <div class="script-container">
        <pre id="script-code-widget" class="script-code">
&lt;div id="reviews-widget" data-company-id="{{ company.id }}"&gt;&lt;/div&gt;
&lt;script src="https://top-offerten.ch/widget/reviews.js"&gt;&lt;/script&gt;
    </pre>
    <button class="copy-button" @click="copyToClipboard('script-code-widget')">{{ $t('general.copy') }}</button>
  </div>

  <div>
    <div ref="widgetContainer"></div>
  </div>

</template>

<script>
  import store from '../../store/mainStore';

  export default {
    name: 'Widget',
    components: {},

    data() {
      return {
        size: 150,
      };
    },
    computed: {
      companyProfile() {
        return store.state.company.companyProfile;
      },
      company() {
        return store.state.company.company;
      },
      label() {
        if (this.company.partner_status) {
          return this.company.partner_status + '-partner.jpg';
        } else {
          return 'partner.jpg';
        }
      },
    },
    mounted() {
      this.loadWidget();
    },
    methods: {
      copyToClipboard(code) {
        var copyText = document.getElementById(code);
        var textArea = document.createElement('textarea');
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      },
      loadWidget() {
        // Erstellen des Widget-Containers
        const widgetHtml = `<div id="reviews-widget" data-company-id="${this.company.id}"></div>`;
        this.$refs.widgetContainer.innerHTML = widgetHtml;

        // Warten, bis der DOM aktualisiert wurde
        this.$nextTick(() => {
          // Einfügen des Skripts
          const script = document.createElement('script');
          script.src = 'https://top-offerten.ch/widget/reviews.js';
          script.onload = () => {
            console.log(script);
            console.log('Widget Script geladen und ausgeführt.');
            if (typeof initWidget === 'function') {
              initWidget();  // Direkter Aufruf von initWidget, nachdem das Skript geladen wurde
            }
          };
          script.onerror = () => {
            console.error('Fehler beim Laden des Widget-Skripts.');
          };
          document.body.appendChild(script);
        });
      },
    },
  };
</script>

<style scoped>
    .script-container {
        position: relative;
        margin: 20px;
    }

    .script-code {
        width: 100%;
        padding: 10px;
        background-color: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 5px;
        font-family: monospace;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .copy-button {
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 5px 10px;
        background-color: #4CAF50;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
</style>
